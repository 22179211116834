export const locales = ['en', 'fr'] as const;
export type Locale = (typeof locales)[number];

export const defaultLocale = locales[0];
export type DefaultLocale = typeof defaultLocale;

/**
 * A mapping of locales to translations for all available {@link locales}.
 *
 * @example
 * { en: 'Hello', fr: 'Bonjour' }
 */
export type LocalizedString<Str extends string = string> = Record<Locale, Str>;

/**
 * A mapping of locales to translations for at least the {@link DefaultLocale},
 * and optionally for other locales.
 *
 * @example
 * { en: 'Hello' } // 'fr' is missing but 'en' is the default locale
 */
export type LocalizedStringWithDefault<Str extends string = string> = Partial<
  LocalizedString<Str>
> &
  Pick<LocalizedString<Str>, DefaultLocale>;

// ----------------------- //
// ---- Translations ----- //
// ----------------------- //

const defaultStrings = {
  pomeTitle: 'pome',
  doItBetter: 'Do it better',
  generateThousandsOfDocuments:
    'Generate thousands of individualized docs in one go.',
  tryItNow: 'Try it now',
  signUp: 'Sign Up!',
  personHoldingAPomegranate:
    'Person over a stack of documents holding a pomegranate.',
  dragDrop: 'Drag. Drop.',
  generate: 'Generate',
  pomeIsSuperEasyToUseL1: 'pome is super easy to use.',
  pomeIsSuperEasyToUseL2:
    'Drag and drop your template document and datasheet, and voilà!',
  pomeIsSuperEasyToUseL3:
    'Thousands of perfectly personalized documents at your disposition.',
  schemaOfPomeCombiningDocuments: 'Schema of pome combining documents',
  sign: 'Sign',
  eSignatureIntegrationL1:
    'Seamless integration with e-Signature providers such as DocuSign,',
  eSignatureIntegrationL2:
    'your documents are sent for signature in minutes, not hours.',
  efficient: 'Efficient',
  timeSaving: 'time-saving',
  and: 'and',
  reliable: 'reliable',
  ourTeam: 'Our Team',
  dataPrivacyNotice: 'Data Privacy Notice',
  termsAndConditions: 'Terms and Conditions',
  // Try It Now component
  tryItNowTitle: 'Try it now',
  template: 'Template',
  datasheet: 'Datasheet',
  documentsWereSuccessfullyGeneratedPrefix: 'The ',
  documentsWereSuccessfullyGeneratedSuffix:
    ' documents were successfully generated!',
  generateDocumentsPrefix: 'Generate ',
  generateDocumentsSuffix: ' documents',
  signUpForMore: 'Sign up for more',
  // Testimonials section
  testimonialsTitle: 'Testimonials',
  // Trusted section
  trustedTitle: 'Powering the best teams',
} as const satisfies Record<string, string>;

const translations = {
  fr: {
    pomeTitle: 'pome',
    doItBetter: 'Faites-le mieux',
    generateThousandsOfDocuments:
      'Publipostez des milliers de documents en un clic.',
    tryItNow: 'Essayer',
    signUp: 'S’inscrire !',
    personHoldingAPomegranate:
      'Personne sur une pile de documents tenant une pomme-grenade.',
    dragDrop: 'Glisser. Déposer.',
    generate: 'Publiposter',
    pomeIsSuperEasyToUseL1: 'pome est très facile à utiliser.',
    pomeIsSuperEasyToUseL2:
      'Glissez-déposez votre template et votre sheet de données, et voilà !',
    pomeIsSuperEasyToUseL3:
      'Des milliers de documents parfaitement générés à votre disposition.',
    schemaOfPomeCombiningDocuments: 'Schéma de pome combinant des documents',
    sign: 'Signer',
    eSignatureIntegrationL1:
      'Intégration transparente des prestataires de signature électronique tels que DocuSign,',
    eSignatureIntegrationL2:
      'vos documents sont envoyés pour signature en quelques minutes.',
    efficient: 'Efficace',
    timeSaving: 'rapide',
    and: 'et',
    reliable: 'fiable',
    ourTeam: 'Notre équipe',
    dataPrivacyNotice: 'Confidentialité des données',
    termsAndConditions: 'Conditions générales',
    // Try It Now component
    tryItNowTitle: 'Essayer pome',
    template: 'Modèle',
    datasheet: 'Feuille de données',
    documentsWereSuccessfullyGeneratedPrefix: '',
    documentsWereSuccessfullyGeneratedSuffix: ' documents ont été générés !',
    generateDocumentsPrefix: 'Générer ',
    generateDocumentsSuffix: ' documents',
    signUpForMore: 'S’inscrire pour plus',
    // Testimonials section
    testimonialsTitle: 'Témoignages',
    // Trusted section
    trustedTitle: 'Les meilleurs nous font confiance',
  },
} as const satisfies Translations;

/** All the localized strings. */
export const localized = {
  ...translations,
  [defaultLocale]: defaultStrings,
} as const;

export type TranslationKey = keyof typeof defaultStrings;
export type LanguageLocalization = Record<TranslationKey, string>;
export type Translations = Record<
  Exclude<Locale, DefaultLocale>,
  LanguageLocalization
>;

// Helpers

export function isNotDefaultLocale<L extends Locale>(
  locale: L,
): locale is Exclude<L, DefaultLocale> {
  return locale !== defaultLocale;
}

export function isDefaultLocale<L extends Locale>(
  locale: L,
): locale is L & DefaultLocale {
  return locale === defaultLocale;
}

export function isValidLocale(locale: string): locale is Locale {
  return (locales as readonly string[]).includes(locale);
}
